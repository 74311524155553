import {
    API__FoodProfileMenuTrendsAndFlavorDataViewModel,
    API__KeyValuePairStringDouble,
    API__MenuTrendsFoodProfileViewModel,
    API__PenetrationSuggestedMacWordViewModel
} from "@apiSchema";
import { equalInLC, ID } from "@datassential/platform-ui-lib";
import {
    PermissionFields,
    PermissionLevelEnum,
    PermissionSection
} from "@models/PermissionSection";
import http from "@core/fetch";

interface IPenetrationPoint {
    label: string;
    value: number;
}

interface IPenetrationSlice {
    id: ID;
    name: string;
    penetration: number;
    oneYearTrend: number;
    fourYearTrend: number;
    fourYearFutureTrend: number;
    points: {
        years: IPenetrationPoint[];
        quarters: IPenetrationPoint[];
        predictedYears: IPenetrationPoint[];
        predictedQuarters: IPenetrationPoint[];
    };
}

export interface IFoodProfileSummaryData {
    categoryName: string;
    loveLikePct: number;
    consumerPreferencesPermissionLevel: PermissionLevelEnum;
    filterDescription: string | null;
    chainsCount: number;
    chainsMoreCommon: string;
    consumerMostCommon: string[];
    downloadLink: string;
    macStage: string;
    suggestedMacKeywords: API__PenetrationSuggestedMacWordViewModel[];
    slices: IPenetrationSlice[];
    cpLink: string;
}

interface IFoodProfileSummary {
    id: ID | null;
    name: string;
    data: PermissionFields & {
        data: IFoodProfileSummaryData;
    };
}

type ApiModel = API__FoodProfileMenuTrendsAndFlavorDataViewModel;

interface IData {
    apiModel?: ApiModel;
}

const FAKE_MAC_DATA = [
    { macStageId: 1, macStageName: 'Inception', words: [] },
    { macStageId: 2, macStageName: 'Adoption', words: [] },
    { macStageId: 3, macStageName: 'Proliferation', words: [] },
    { macStageId: 4, macStageName: 'Ubiquity', words: [] },
];

const getFakeSliceData = (currentYear: number): IPenetrationSlice => ({
    id: 0,
    name: 'Fake Slice',
    penetration: 100,
    oneYearTrend: 10,
    fourYearTrend: 20,
    fourYearFutureTrend: 30,
    points: {
        years: [
            { label: `${currentYear - 10}`, value: 51.325199831720653},
            { label: `${currentYear - 9}`, value: 56.499789650820361},
            { label: `${currentYear - 8}`, value: 56.668051516410472},
            { label: `${currentYear - 7}`, value: 57.250313414124527},
            { label: `${currentYear - 6}`, value: 57.853457172342623},
            { label: `${currentYear - 5}`, value: 58.532563891178896},
            { label: `${currentYear - 4}`, value: 58.067831449126416},
            { label: `${currentYear - 3}`, value: 60.369989722507711},
            { label: `${currentYear - 2}`, value: 61.042439225381131},
            { label: `${currentYear - 1}`, value: 61.527377521613836},
        ],
        quarters: [
            { label: `${currentYear} Q1`, value: 51.325199831720653 },
            { label: `${currentYear} Q2`, value: 56.499789650820361 },
            { label: `${currentYear} Q3`, value: 56.668051516410472 },
            { label: `${currentYear} Q4`, value: 57.250313414124527 },
        ],
        predictedQuarters: [],
        predictedYears: [
            { label: `${currentYear + 1}`, value: 61.356141797197033 },
            { label: `${currentYear + 2}`, value: 61.356141797197033 },
            { label: `${currentYear + 3}`, value: 61.356141797197033 },
            { label: `${currentYear + 4}`, value: 61.356141797197033 },
        ],
    }
});

export class FoodProfileSummary implements IFoodProfileSummary {
    static defaultData: IFoodProfileSummary = {
        id: null,
        name: '',
        data: {
            hasData: true,
            permissionLevel: PermissionLevelEnum.Full,
            data: {
                categoryName: 'All foods',
                filterDescription: null,
                chainsCount: 0,
                chainsMoreCommon: '',
                consumerMostCommon: [],
                downloadLink: '',
                macStage: '',
                suggestedMacKeywords: FAKE_MAC_DATA,
                loveLikePct: 0,
                consumerPreferencesPermissionLevel: PermissionLevelEnum.Full,
                slices: [],
                cpLink: '',
            },
        },
    };

    static getFakeData (currentYear: number): IFoodProfileSummary {
        return {
            id: 1,
            name: 'Fake Food Profile',
            data: {
                hasData: true,
                permissionLevel: PermissionLevelEnum.Full,
                data: {
                    categoryName: 'All foods',
                    filterDescription: 'Fake filter description',
                    chainsCount: 10,
                    chainsMoreCommon: 'Fake chains more common',
                    consumerMostCommon: [],
                    downloadLink: 'Fake download link',
                    macStage: 'Fake mac stage',
                    suggestedMacKeywords: FAKE_MAC_DATA,
                    loveLikePct: 50,
                    consumerPreferencesPermissionLevel: PermissionLevelEnum.Limited,
                    slices: [
                        { ...getFakeSliceData(currentYear), id: 1, name: 'Total US', },
                        { ...getFakeSliceData(currentYear), id: 2, name: 'QSR', },
                        { ...getFakeSliceData(currentYear), id: 3, name: 'Fast Casual', },
                        { ...getFakeSliceData(currentYear), id: 4, name: 'Midscale', },
                        { ...getFakeSliceData(currentYear), id: 5, name: 'Casual Dining', },
                        { ...getFakeSliceData(currentYear), id: 6, name: 'Fine Dining', },
                    ],
                    cpLink: '',
                },
            },
        };
    }

    id = FoodProfileSummary.defaultData.id;
    name = FoodProfileSummary.defaultData.name;
    data = FoodProfileSummary.defaultData.data;

    constructor(data?: IData) {
        if (data) {
            if (data.apiModel) {
                this.mapFromApi(data.apiModel);
            }
        }
    }

    private setData(model: Partial<IFoodProfileSummary>) {
        Object.assign(this, model);
    }

    private mapFromApi(apiModel: ApiModel) {
        const {
            foodProfileId,
            foodProfileWord,
            consumerPreferencesData,
            chainsCount,
            menuTrendsData,
            currentYear,
        } = apiModel;

        const {
            commonRestaurantType,
            encryptedReportId,
            encryptedReportName,
            filterDescription,
            macStage,
            suggestedMacWords,
            penetration,
            categoryName,
        } = menuTrendsData.data ?? {};

        const mtPermissionLevel = PermissionSection.getPermissionLevel(menuTrendsData.permissionLevel);

        const { loveLikeIt, keywordUrl } = consumerPreferencesData.consumerPreferencesWordData.data ?? {};

        this.setData({
            id: foodProfileId,
            name: foodProfileWord,
            data: {
                hasData: apiModel.menuTrendsData.hasData,
                permissionLevel: mtPermissionLevel,
                data: mtPermissionLevel === PermissionLevelEnum.Full ? {
                    categoryName: categoryName ?? FoodProfileSummary.defaultData.data.data?.categoryName,
                    chainsCount: chainsCount ?? FoodProfileSummary.defaultData.data.data?.chainsCount,
                    chainsMoreCommon: FoodProfileSummary.getNormalizedChainsCommonTitle(commonRestaurantType ?? ''),
                    consumerMostCommon: FoodProfileSummary.getPopularityGroupsTitles(consumerPreferencesData),
                    downloadLink: FoodProfileSummary.getDownloadLink(encryptedReportId ?? '', encryptedReportName ?? ''),
                    macStage: macStage ?? FoodProfileSummary.defaultData.data.data?.macStage,
                    suggestedMacKeywords: suggestedMacWords ?? FoodProfileSummary.defaultData.data.data?.suggestedMacKeywords,
                    filterDescription: filterDescription ?? FoodProfileSummary.defaultData.data.data?.filterDescription,
                    loveLikePct: loveLikeIt?.generalData.raw ?? FoodProfileSummary.defaultData.data.data?.loveLikePct,
                    consumerPreferencesPermissionLevel: PermissionSection.getPermissionLevel(consumerPreferencesData.consumerPreferencesWordData.permissionLevel),
                    slices: [
                        FoodProfileSummary.getSlice('all', penetration ?? {}),
                        FoodProfileSummary.getSlice('qsr', penetration ?? {}),
                        FoodProfileSummary.getSlice('fast casual', penetration ?? {}),
                        FoodProfileSummary.getSlice('midscale', penetration ?? {}),
                        FoodProfileSummary.getSlice('casual dining', penetration ?? {}),
                        FoodProfileSummary.getSlice('fine dining', penetration ?? {}),
                    ],
                    cpLink: keywordUrl ?? FoodProfileSummary.defaultData.data.data?.cpLink,
                } : FoodProfileSummary.getFakeData(currentYear).data.data,
            },
        });
    }

    static getDownloadLink(reportId: string, reportName: string) {
        return http.getQuery('download-report', {
            reportId,
            reportName,
            wordName: 'foodProfile'
        });
    }

    static getSlice(sliceName: keyof API__MenuTrendsFoodProfileViewModel['penetration'], data: API__MenuTrendsFoodProfileViewModel['penetration']): IPenetrationSlice {
        const key = Object.keys(data).find(key => equalInLC(key, `${sliceName}`)) ?? '';
        const rawData = data[key] ?? {};

        let title = '';

        switch (sliceName) {
            case 'all': {
                title = 'Total US';
                break;
            }
            case 'qsr': {
                title = 'QSR';
                break;
            }
            case 'fast casual': {
                title = 'Fast Casual';
                break;
            }
            case 'midscale': {
                title = 'Midscale';
                break;
            }
            case 'casual dining': {
                title = 'Casual Dining';
                break;
            }
            case 'fine dining': {
                title = 'Fine Dining';
                break;
            }
        }

        function mapPoints (data: API__KeyValuePairStringDouble): IPenetrationPoint {
            return {
                label: data.key,
                value: data.value,
            };
        }

        return {
            id: sliceName,
            name: title,
            penetration: rawData.currentPenetration ?? 0,
            oneYearTrend: rawData.historic.oneYearTrend ?? 0,
            fourYearTrend: rawData.historic.fourYearTrend ?? 0,
            fourYearFutureTrend: rawData.prediction.fourYearTrend ?? 0,
            points: {
                years: rawData.historic.years.map(mapPoints),
                quarters: rawData.historic.quarters.map(mapPoints),
                predictedYears: rawData.prediction.years.map(mapPoints),
                predictedQuarters: rawData.prediction.quarters.map(mapPoints),
            },
        };
    }

    static getNormalizedChainsCommonTitle(commonRestaurantType: string): string {
        switch (commonRestaurantType.toLowerCase()) {
            case 'independents': {
                return 'independent';
            }
            case 'chain': {
                return 'chain';
            }
            case 'regional': {
                return 'regional chain';
            }
            default: {
                return '';
            }
        }
    }

    static getPopularityGroupsTitles(data: ApiModel["consumerPreferencesData"]): string[] {
        if (data.consumerPreferencesWordData.hasData && data.consumerPreferencesWordData.data) {
            let {
                config: {
                    respondentGroupTypeInfo,
                },
                consumerPreferencesWordData: {
                    data: {
                        mostPopular: {
                            ethnicities,
                            genders,
                            generations,
                            regions,
                        },
                    },
                },
            } = data;

            const respondentsTypes = respondentGroupTypeInfo.flatMap(g => g.types);

            const getOptionsNames = (optionsIds: number[]) => {
                return respondentsTypes
                    .filter(i => optionsIds.includes(i.id))
                    .map(i => i.name);
            };

            let ethnicitiesNames = getOptionsNames(ethnicities || []);
            let gendersNames = getOptionsNames(genders || []);
            let generationsNames = getOptionsNames(generations || []);
            let regionsNames = getOptionsNames(regions || []);

            return [
                (ethnicitiesNames || []).join(' | '),
                (gendersNames || []).join(' | '),
                (generationsNames || []).join(' | '),
                (regionsNames || []).join(' | '),
            ].filter(i => !!i);
        }

        return [];
    }

}