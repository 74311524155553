import { equalInLC, FCX, ID, Tooltip } from "@datassential/platform-ui-lib";
import { Link } from "react-router-dom";
import { routeNames } from "@core/routeNames";
import './MacStageMenu.scss';
import cn from "classnames";
import { IFoodProfileSummaryData } from "@models/NewFoodProfileSummary";
import { useMemo } from "react";
import { API__PenetrationSuggestedMacWordViewModel } from "@apiSchema";

export type FoodPipelineStage = 'inception' | 'adoption' | 'proliferation' | 'ubiquity';

export interface IStage {
    id: ID;
    stage: FoodPipelineStage;
    name: string;
    isActive: boolean;
    words: Array<{
        id: ID;
        name: string;
    }>;
}

const FoodMacStageMenu: FCX<{
    macStage: IFoodProfileSummaryData['macStage'];
    suggestedMacKeywords: IFoodProfileSummaryData['suggestedMacKeywords'];
    categoryName: IFoodProfileSummaryData['categoryName'];
}> = ({
    macStage,
    suggestedMacKeywords,
    categoryName,
}) => {
    const data = useMemo(
        (): IStage[] => {
            const getStageByName = (stage: string) => suggestedMacKeywords.find(i => equalInLC(i.macStageName, stage));
            const mapStage = (type: FoodPipelineStage, stageData: API__PenetrationSuggestedMacWordViewModel): IStage => {
                return {
                    id: stageData.macStageId,
                    stage: type,
                    name: stageData.macStageName,
                    isActive: equalInLC(macStage, stageData.macStageName),
                    words: stageData.words.slice(0, 7).map(word => ({
                        id: word.id,
                        name: word.name,
                    })),
                };
            };
            const inception = getStageByName('inception');
            const adoption = getStageByName('adoption');
            const proliferation = getStageByName('proliferation');
            const ubiquity = getStageByName('ubiquity');

            const result = [];

            if (inception) result.push(mapStage('inception', inception));
            if (adoption) result.push(mapStage('adoption', adoption));
            if (proliferation) result.push(mapStage('proliferation', proliferation));
            if (ubiquity) result.push(mapStage('ubiquity', ubiquity));

            return result;
        },
        [suggestedMacKeywords, macStage]
    );

    if (data.length === 0) return null;

    return (
        <div className="FoodMacStageMenu">
            {data.map((item) => (
                <div
                    key={item.id}
                    className={cn(
                        "FoodMacStageMenu__item",
                        item.isActive && 'is-active',
                        `stage-${item.stage}`
                    )}
                    data-tooltip-id={`mac-stage-${item.stage}`}
                >
                    <div className="FoodMacStageMenu__item-bullet"/>
                    <div className="FoodMacStageMenu__item-title">{item.name}</div>
                    <Tooltip
                        id={`mac-stage-${item.stage}`}
                        place="bottom"
                        clickable
                    >
                        <div className="FoodMacStageMenu__hover-popup">
                            <div>Other {categoryName} in the {item.name.toUpperCase()} stage:</div>
                            <br/>
                            <div className="FoodMacStageMenu__hover-list">
                                {item.words.map((word) => (
                                    <Link
                                        key={word.id}
                                        className="FoodMacStageMenu__hover-item"
                                        to={{
                                            pathname: routeNames.food,
                                            search: `?q=${encodeURIComponent(word.name.toLowerCase())}`
                                        }}
                                    >
                                        {word.name}
                                    </Link>
                                ))}
                            </div>
                        </div>
                    </Tooltip>
                </div>
            ))}
        </div>
    );
}

export default FoodMacStageMenu;