import { FC, useCallback, useContext, useState } from "react";
import qs from "query-string";
import Button from "@components/new/Button/Button";
import NewTopChains from "./NewTopChains";
import Page from "@containers/Page/Page";
import PageContent from "@containers/PageContent/PageContent";
import Loader from "@components/new/Loader/Loader";
import { getFormattedThousand } from "@helpers";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../AppContext";
import WhoOffers from "./WhoOffers";
import { useOfferingChains } from "@apiHooks/useOfferingChains";
import Tabs from "@components/new/Tabs/Tabs";
import { ChainsFilters, iChainsFilters } from "@models/ChainsFilters";
import { useChainsExport } from "@apiHooks/useChainsExport";
import NotificationPopup from "@components/NotificationPopup/NotificationPopup";
import { getEnding } from "@datassential/platform-ui-lib";

enum PlacesTabs {
    Places = 1,
    Foods = 2,
}

const Places: FC = () => {
    const {
        globalVariables: {
            hasFFChainsFeaturePermission: hasPermission,
        },
    } = useContext(AppContext);
    const location = useLocation();
    const parsedSearch = qs.parse(location.search);
    const searchQuery = parsedSearch.q as string;
    const possibleTabIndex = +(parsedSearch.tabIndex as string);
    const [tabIndex, setTabIndex] = useState<PlacesTabs>(possibleTabIndex || PlacesTabs.Places);
    const [selectedFilters, setSelectedFilters] = useState<iChainsFilters>(new ChainsFilters());
    const [selectedChainsIds, setSelectedChainsIds] = useState<number[]>([]);

    const {
        isLoading,
        data: {
            menuItemsData,
            chainsCount,
            ...placesData
        },
    } = useOfferingChains(searchQuery);

    const updateTabIndex = useCallback(
        (newTabIndex: PlacesTabs) => {
            setTabIndex(newTabIndex);
            const path = window.location.pathname;
            const searchParams = new URLSearchParams(window.location.search);

            if (newTabIndex === PlacesTabs.Places) {
                searchParams.delete('tabIndex');
            } else {
                searchParams.set('tabIndex', newTabIndex.toString());
            }

            window.history.replaceState(null, '', `${path}?${searchParams.toString()}`);
        },
        [],
    );

    const {
        requestExportChains,
        isDownloading,
        isNotificationShow,
    } = useChainsExport(selectedChainsIds, placesData.filtersData, selectedFilters, searchQuery);

    const handleFiltersChange = (selectedFilters: iChainsFilters, selectedChainsIds: number[]) => {
        setSelectedFilters(selectedFilters);
        setSelectedChainsIds(selectedChainsIds)
    };

    const [count, setCount] = useState(0);

    const title = tabIndex === 1
        ?<>{getFormattedThousand(chainsCount)}&nbsp;{getEnding('chain', count)} offering&nbsp;<span>{searchQuery}</span></>
        : <>chain menu items featuring&nbsp;<span>{searchQuery}</span></>;

    return (
        /* @ts-ignore */
        <Page>
            <PageContent>
                <div className="NewTopChains is-places">
                    <NotificationPopup
                        isOpened={isNotificationShow}
                        hasCloseButton={false}
                        style={{ width: "auto", height: "auto" }}
                        className="notification-popup--page"
                    >
                        <span>Report is ready to save...</span>
                    </NotificationPopup>
                    <div className="NewTopChains__header">
                        <div
                            className="NewTopChains__part"
                            data-testid={`text${tabIndex === PlacesTabs.Places ? 'Places' : 'Foods'}Title`}
                        >
                            {!isLoading && title}
                        </div>
                        <div className="NewTopChains__part">
                            <div className="NewTopChains__tabs">
                                <Tabs
                                    selectedId={tabIndex}
                                    onChange={(id) => updateTabIndex(id as PlacesTabs)}
                                    items={[
                                        { id: 1, title: 'Places' },
                                        { id: 2, title: 'Foods' },
                                    ]}

                                />
                            </div>
                        </div>
                    </div>
                    <div className="NewTopChains__header">
                        <div className="NewTopChains__part"/>
                        <div className="NewTopChains__part">
                            {tabIndex === 1 && hasPermission && (
                                <Button
                                    className="NewTopChains__button"
                                    modifiers={["primary", "accent"]}
                                    onClick={requestExportChains}
                                    isLoading={isDownloading}
                                    testId={"buttonPlacesExportXls"}
                                >
                                    XLS Export
                                </Button>
                            )}
                        </div>
                    </div>
                    {isLoading && <Loader/>}
                    {!isLoading && (
                        <>
                            {tabIndex === 1 && (
                                <NewTopChains
                                    data={placesData}
                                    setCount={setCount}
                                    handleFiltersChange={handleFiltersChange}
                                    tableTestId={"tablePlaces"}
                                />
                            )}
                            {tabIndex === 2 && (
                                <WhoOffers
                                    setCount={setCount}
                                    data={menuItemsData}
                                />
                            )}
                        </>
                    )}
                </div>
            </PageContent>
        </Page>
    );
};

export default Places;