import { FCX, ID } from "@datassential/platform-ui-lib";
import { ReactNode } from "react";
import cn from "classnames";
import './Tabs.scss';

export interface ITab {
    id: ID;
    name: ReactNode;
}

const FoodTabs: FCX<{
    data: ITab[];
    activeItemIndex: ID;
    setActiveItemIndex: (index: number) => void;
}> = ({
    data,
    activeItemIndex,
    setActiveItemIndex,
}) => {
    return (
        <div className="FoodTabs">
            {data.map((item, index) => (
                <div
                    key={item.id}
                    className={cn(
                        "FoodTabs__item",
                        index === activeItemIndex && ' is-active',
                    )}
                    onClick={() => setActiveItemIndex(index)}
                >
                    {item.name}
                </div>
            ))}
        </div>
    );
};

export default FoodTabs;