export const BASE_CHART_HEIGHT = 320;

export enum PointType {
    Solid = 'solid',
    Dashed = 'dashed',
    SolidActive = 'solid-active',
    DashedActive = 'dashed-active',
}

export interface iViewBoxProps {
    canvasWidth: number;
    canvasHeight: number;
    width: number;
    height: number;
    padding: {
        top: number;
        bottom: number;
        left: number;
        right: number;
    };
    proportion: number;
}

export interface iPoint {
    x: number;
    y: number;
}

export interface iYearPoint {
    label: string;
    value: number;
    type?: PointType;
    tooltipLabel?: string;
    tooltipValues?: number[];
    tooltipValuesSeparatorIndex?: number;
}

export function createGetYFn(
    { chartValues, height }: {
        chartValues: number[];
        height: number
    }
): (y: number) => number {
    let maxValue = -Infinity;
    let minValue = Infinity;

    chartValues.forEach(value => {
        maxValue = Math.max(maxValue, value);
        minValue = Math.min(minValue, value);
    });

    let min = minValue;
    let max = maxValue;

    if (maxValue > 10) {
        max = maxValue + 8;
        min = Math.max(-0.1, minValue - 3);
    } else if (maxValue > 3) {
        max = maxValue + 2;
        min = Math.max(-0.1, minValue - 2);
    } else if (maxValue > 1) {
        max = maxValue + 1;
        min = Math.max(-0.1, minValue - 1);
    } else {
        max = 1.5;
        min = Math.max(-0.1, min - 1);
    }

    min = min - (max - min) * 0.3;

    const proportion = height / ((max - min) + 1);

    return function (value: number) {
        return (max - value) * proportion;
    };
}

export function getChartXIntervals(
    {
        dataLength,
        viewport,
    }: {
        dataLength: number;
        viewport: iViewBoxProps;
    }
): number[] {
    const intervalWidth = viewport.width / (dataLength - 1);
    return Array(dataLength).fill(null).map((_, index) => intervalWidth * index);
}

export function getChartPoints(
    {
        data,
        viewport,
        getY,
    }: {
        data: iYearPoint[];
        viewport: iViewBoxProps;
        getY: (value: number) => number;
    }
): iPoint[] {
    const intervals = getChartXIntervals({ dataLength: data.length, viewport, })
    const chartPoints: iPoint[] = [];

    data.forEach((year, yearIndex) => {
        const { value } = year;

        chartPoints.push({
            x: intervals[yearIndex],
            y: getY(value),
        });
    });

    return chartPoints;
}

export function getPath(points: iPoint[]): string {
    let result = '';

    points.forEach((point, index) => {
        result += ` ${index === 0 ? 'M' : 'L'} ${point.x} ${point.y}`;
    });

    return result;
}
