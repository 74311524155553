import { FC, memo, useMemo } from "react";
import { getPath, iPoint } from "./SummaryChart.helpers";

export enum LineStyle {
    Solid,
    Dashed,
}

const ChartCurve: FC<{
    points: iPoint[];
    color: string;
    lineStyle: LineStyle;
}> = (
    {
        points,
        color,
        lineStyle,
    }
) => {
    const d: string = useMemo(() => {
        if (points.length === 0) return '';

        return getPath(points);
    }, [points]);

    return (
        <g className="FoodSummaryChart__curve">
            <path
                d={d}
                stroke={color}
                strokeWidth={1}
                strokeDasharray={lineStyle === LineStyle.Dashed ? '3 6' : ''}
                fill="none"
                strokeLinecap="round"
            />
        </g>
    );
};

export default memo(ChartCurve);