import Http from '../fetch';
import { useData } from "@hooks/useData";
import {
    API__ChainMenuAndLtosViewModel,
    API__PlacesWithMenuItemsViewModel,
} from "@apiSchema";
import { iTopChainsData, TopChainsData } from "@models/TopChainsData";

export interface OfferingChainsData extends iTopChainsData {
    chainsCount: number;
    menuItemsData: API__ChainMenuAndLtosViewModel[];
}

async function getOfferingChains(q: string): Promise<OfferingChainsData> {
    const data: API__PlacesWithMenuItemsViewModel  = await Http.get('offering chains + items + count', { SearchWord: q });

    return {
        ...new TopChainsData({ apiModel: data.chainsWithMenuItems }),
        chainsCount: data.chainsCount,
        menuItemsData: data.chainsWithMenuItems.map((i) => ({
            chainId: i.id,
            chainName: i.chainName,
            menuItems: i.menuItems,
        })),
    };
}

export function useOfferingChains(q: string) {
    return useData({
        ...new TopChainsData(),
        chainsCount: 0,
        menuItemsData: [],
    }, getOfferingChains, q);
}