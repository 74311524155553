import React, { FC, memo, useCallback, useMemo, useRef, useState } from "react";
import {
    createGetYFn,
    getChartPoints,
    iViewBoxProps,
    iYearPoint,
    iPoint,
    PointType
} from "./SummaryChart.helpers";
import ChartAxisX from "./ChartAxisX";
import ChartArea from "./ChartArea";
import './SummaryChart.scss';
import ChartCurve, { LineStyle } from "./ChartCurve";
import ChartDots from "./ChartDots";
import cn from "classnames";
import { iTooltipRow } from "./ChartTooltipMultiline/ChartTooltipMultiline.helpers";
import ChartTooltipMultiline from "./ChartTooltipMultiline/ChartTooltipMultiline";

const ChartPredicted: FC<{
    pastDataAll: iYearPoint[][];
    currentDataAll: iYearPoint[];
    futureDataAll: iYearPoint[][];
    activeChartIndex: number;
    width: number;
    height: number;
    printValue: (value: number) => string;
    chartsTitles: string[];
}> = (
    {
        pastDataAll,
        currentDataAll,
        futureDataAll,
        activeChartIndex,
        width,
        height,
        printValue,
        chartsTitles,
    }
) => {
    const [activePointIndex, setActivePointIndex] = useState(-1);
    const [isTooltipShown, setIsTooltipShown] = useState(false);
    const tooltipHideTimer = useRef<any>(null);

    const pastData = useMemo(
        () => pastDataAll[activeChartIndex],
        [pastDataAll, activeChartIndex]
    );

    const currentData = useMemo(
        () => currentDataAll[activeChartIndex],
        [currentDataAll, activeChartIndex]
    );

    const futureData = useMemo(
        () => futureDataAll[activeChartIndex],
        [futureDataAll, activeChartIndex]
    );

    const viewport = useMemo<iViewBoxProps>(() => {
        const padding = {
            top: 20,
            bottom: 40,
            left: 40,
            right: 40,
        };

        return {
            width: width - padding.left - padding.right,
            height: height - padding.top - padding.bottom,
            canvasWidth: width,
            canvasHeight: height,
            padding,
            proportion: 1,
        }
    }, [width, height, futureData]);

    const allYears: iYearPoint[] = useMemo(
        () => [
            ...pastData,
            currentData,
            ...futureData,
        ],
        [pastData, futureData, currentData]
    );

    const getY = useMemo(
        () => {
            return createGetYFn({
                height: viewport.height,
                chartValues: allYears.map(y => y.value),
            });
        },
        [allYears, viewport]
    );

    const realPartWidth = useMemo(
        () => {
            const intervalWidth = viewport.width / (pastData.length + futureData.length);

            return intervalWidth * (pastData.length + 0.5);
        },
        [pastData, futureData, viewport]
    );

    const allPoints = useMemo(
        () => {
            return getChartPoints({
                data: allYears,
                getY,
                viewport,
            });
        },
        [allYears, getY, viewport]
    );

    const menucastTextBoxPoint = useMemo(
        () => {
            const lastPoint = allPoints[allPoints.length - 1];
            return {
                x: realPartWidth + viewport.padding.left,
                y: viewport.padding.top + lastPoint.y,
                height: viewport.height - lastPoint.y,
                width: viewport.width - realPartWidth
            };

        },
        [viewport, allPoints, realPartWidth]
    );

    const getPointType = useCallback((pointIndex): PointType => {
        if (pointIndex <= pastData.length) {
            return PointType.Solid;
        } else {
            return PointType.Dashed;
        }
    }, [pastData, currentData]);

    const tooltipData: iTooltipRow[] = useMemo(
        () => {
            if (activePointIndex >= 0) {
                return pastDataAll.map((_, chartIndex) => {
                    const allData = [
                        ...pastDataAll[chartIndex],
                        currentDataAll[chartIndex],
                        ...futureDataAll[chartIndex],
                    ];
                    const yearData = allData[activePointIndex];
                    let values = [];
                    let isSubValuesMode = false;

                    if (yearData.tooltipValues && yearData.tooltipValues.length > 0) {
                        isSubValuesMode = true;
                        values = yearData.tooltipValues.map(printValue);
                    } else {
                        values = [printValue(yearData.value)];
                    }

                    return {
                        title: chartsTitles[chartIndex],
                        isActive: activeChartIndex === chartIndex,
                        isSubValuesMode,
                        values,
                    };
                });
            }
            return [];
        },
        [activeChartIndex, activePointIndex, printValue]
    );

    const tooltipColumns = useMemo(
        () => {
            const isSubValuesMode = tooltipData.some(i => i.isSubValuesMode);

            if (tooltipData[0] && isSubValuesMode) {
                return ['Q1', 'Q2', 'Q3', 'Q4'].slice(0, tooltipData[0].values.length);
            }

            return [allYears[activePointIndex]?.label ?? ''];
        },
        [tooltipData, allYears, activePointIndex]
    );

    const showTooltip = useCallback((index: number) => {
        setIsTooltipShown(true);
        setActivePointIndex(index);
        clearTimeout(tooltipHideTimer.current);
    }, [setIsTooltipShown, setActivePointIndex, tooltipHideTimer.current]);

    const hideTooltip = useCallback(() => {
        setIsTooltipShown(false);
        tooltipHideTimer.current = setTimeout(setActivePointIndex, 500);
    }, [setIsTooltipShown, setActivePointIndex, tooltipHideTimer.current]);

    const chartDotsData = useMemo(
        () => allYears.map((year, yearIndex) => ({
            x: allPoints[yearIndex].x,
            y: allPoints[yearIndex].y,
            label: printValue(year.value),
            type: getPointType(yearIndex),
            onMouseEnter: () => showTooltip(yearIndex),
            onMouseLeave: hideTooltip,
        })),
        [allYears, allPoints, getPointType, showTooltip, hideTooltip]
    );

    const tooltipCoords: iPoint = useMemo(
        () => {
            if (activePointIndex >= 0) {
                return {
                    x: allPoints[activePointIndex].x + viewport.padding.left,
                    y: allPoints[activePointIndex].y + viewport.padding.top,
                };
            }
            return { x: 0, y: 0, };
        },
        [activePointIndex, allPoints, viewport]
    );

    return (
        <div
            className="FoodSummaryChart"
            style={{ width, height }}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={viewport.width + viewport.padding.left + viewport.padding.right}
                height={viewport.height + viewport.padding.top + viewport.padding.bottom}
                viewBox={`0 0 ${viewport.width + viewport.padding.left + viewport.padding.right} ${viewport.height + viewport.padding.top + viewport.padding.bottom}`}
            >
                <g
                    name="viewport"
                    viewBox={`0 0 ${viewport.width} ${viewport.height}`}
                    style={{
                        width: viewport.width,
                        height: viewport.height,
                        transform: `translate(${viewport.padding.left}px, ${viewport.padding.top}px)`,
                        position: 'relative',
                    }}
                >
                    <clipPath id="solid">
                        <rect
                            x={0}
                            y={0}
                            height={viewport.height + viewport.padding.top + viewport.padding.bottom}
                            width={realPartWidth}
                        />
                    </clipPath>
                    <clipPath id="dashed">
                        <rect
                            x={realPartWidth}
                            y={-viewport.padding.top}
                            height={viewport.height + viewport.padding.top + viewport.padding.bottom}
                            width={viewport.width - realPartWidth}
                        />
                    </clipPath>

                    <g clipPath={'url(#solid)'}>
                        <ChartArea
                            points={allPoints}
                            color="#fff"
                            opacity={0.1}
                            viewport={viewport}
                        />
                        <ChartCurve
                            points={allPoints}
                            color="#fff"
                            lineStyle={LineStyle.Solid}
                        />
                    </g>

                    <g clipPath={'url(#dashed)'}>
                        <ChartCurve
                            points={allPoints}
                            color="#fff"
                            lineStyle={LineStyle.Dashed}
                        />
                        <ChartArea
                            points={allPoints}
                            color="#fff"
                            opacity={0.2}
                            viewport={viewport}
                        />
                    </g>

                    <ChartAxisX
                        labels={allYears.map(y => y.label)}
                        viewport={viewport}
                    />

                </g>
            </svg>
            <ChartDots
                data={chartDotsData}
                viewport={viewport}
            />
            <div
                className="FoodSummaryChart__prediction-thumb-container"
                style={{
                    transform: `translate(${menucastTextBoxPoint.x}px, ${menucastTextBoxPoint.y}px)`,
                    height: menucastTextBoxPoint.height,
                    width: menucastTextBoxPoint.width,
                }}
                data-testid="buttonChartOpenPopup"
            >
                <div
                    className="FoodSummaryChart__prediction-thumb-title"
                    data-testid="textChartMenucastTitle"
                >
                    Menucast
                </div>
                <div
                    className="FoodSummaryChart__prediction-thumb-sub-title"
                    data-testid="textChartMenucastSubTitle"
                >
                    AI-POWERED PREDICTIONS
                </div>
            </div>
            <div
                className="FoodSummaryChart__future-label"
                style={{
                    transform: `translate(${realPartWidth + viewport.padding.left}px, ${viewport.padding.top + viewport.height + 20}px)`,
                    width: viewport.width - realPartWidth,
                }}
            >
                future
            </div>
            {activePointIndex >= 0 && (
                <div className={cn("Chart__tooltip", isTooltipShown && "is-shown")}>
                    <ChartTooltipMultiline
                        title={allYears[activePointIndex].tooltipLabel ?? ''}
                        rows={tooltipData}
                        columns={tooltipColumns}
                        columnSeparatorIndex={allYears[activePointIndex].tooltipValuesSeparatorIndex}
                        x={tooltipCoords.x}
                        y={tooltipCoords.y}
                        position={"bottom"}
                        align={activePointIndex === allYears.length - 1 ? 'right' : 'center'}
                        hasHeader={tooltipColumns.length > 0 && !tooltipColumns.includes('')}
                    />
                </div>
            )}
        </div>
    );
};

export default memo(ChartPredicted);