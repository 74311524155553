import { CSSProperties, FC, memo, useMemo } from "react";
import { getPath, iPoint, iViewBoxProps } from "./SummaryChart.helpers";

const ChartArea: FC<{
    points: iPoint[];
    color?: string;
    gradientId?: string;
    opacity?: number;
    viewport: iViewBoxProps;
    style?: CSSProperties;
}> = (
    {
        points,
        color,
        gradientId,
        opacity = 1,
        viewport,
        style,
    }
) => {
    const d: string = useMemo(() => {
        if (points.length === 0) return '';

        return getPath([
            { x: points[0].x, y: viewport.height, },
            ...points,
            { x: points[points.length - 1].x, y: viewport.height, },
        ]) + ' Z';
    }, [points, viewport]);

    return (
        <g
            className="FoodSummaryChart__area"
            style={style}
        >
            <path
                d={d}
                fill={!!gradientId ? `url(#${gradientId})` : color}
                fillOpacity={opacity}
            />
        </g>
    );
};

export default memo(ChartArea);